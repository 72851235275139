import { SearchContainer } from './SearchContainer.styled';
import { StyledInput } from './StyledInput.styled';
import { StyledMagnifyingGlass } from './StyledMagnifyingGlass.styled';
import type { FC } from 'react';

export const SearchBar: FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}> = ({ onChange, placeholder }) => (
  <SearchContainer>
    <StyledMagnifyingGlass size={20} weight="bold" />
    <StyledInput onChange={onChange} placeholder={placeholder} />
  </SearchContainer>
);
