import styled from 'styled-components';

export const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
`;
