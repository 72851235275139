import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const SearchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: ${getDimension('size1')};

  background-color: ${props => props.theme.colors.white};
  opacity: 0.7;
  transition: 250ms opacity;
  will-change: opacity;

  &:hover,
  :focus-within {
    opacity: 1;
  }
`;
