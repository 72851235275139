import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { useCallback, useMemo, useState } from 'react';
import Fuse from 'fuse.js';

import { Seo } from 'components/Seo';
import { HeroContainer } from 'components/Partners/HeroContainer';
import { Layout } from 'components/Layout';
import { Container, Section } from 'components/Container';
import { PartnerCards } from 'components/Partners';
import { PaginationButtons } from 'components/PaginationButtons';

import type { FC } from 'react';
import type { Partner } from 'types/partners/partner';

interface PageContext {
  currentPageNumber: number;
  entries: Partner[];
  prevPath?: string;
  nextPath?: string;
  sortedPartners: Partner[];
  mostPopularPartners: Partner[];
}

interface PartnerIndexProps {
  pageContext: PageContext;
}

/**
 * This creates the single `/partners` page.
 * It lives here as the method of creation is via the createPage({}) function so that it can receive the necessary partners and categories props.
 */
const PartnerIndex: FC<PartnerIndexProps> = ({ pageContext }) => {
  const { currentLocale } = useLocalizationContext();
  const [searchPartners, setSearchPartners] = useState<Partner[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const fuse = useMemo(
    () => new Fuse(pageContext.sortedPartners, { keys: ['title', 'location'], threshold: 0.4 }),
    [pageContext.sortedPartners]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);

      const resultPartners = fuse.search(e.target.value).map(result => result.item);
      setSearchPartners(resultPartners);
    },
    [fuse]
  );

  const localePriorityPartners = useMemo(
    () =>
      pageContext.mostPopularPartners?.filter(
        partner =>
          !!partner.mostPopularPartnerLocales &&
          partner.mostPopularPartnerLocales.find(locale => locale === currentLocale.id)
      ),
    [pageContext.mostPopularPartners, currentLocale.id]
  );

  return (
    <Layout hideFooterCta>
      <Seo />
      <HeroContainer onChange={onChange} />

      {searchValue ? (
        <Container padding={15} color="cream">
          <Section>
            <h4>Search results</h4>
            <br />
            <PartnerCards entries={searchPartners} includeAddTradeTile />
          </Section>
        </Container>
      ) : (
        <>
          {pageContext.currentPageNumber === 1 && localePriorityPartners.length > 0 && (
            <Container padding={15} color="cream">
              <Section>
                <h4>Most popular</h4>
                <br />
                <PartnerCards entries={localePriorityPartners} />
              </Section>
            </Container>
          )}
          <Container manageLayout={false} padding={8} color="cream">
            <Section>
              <h4>A - Z</h4>
              <br />
              <PartnerCards entries={pageContext.entries} includeAddTradeTile />
            </Section>

            <PaginationButtons
              containerColor="cream"
              prevPath={pageContext.prevPath}
              nextPath={pageContext.nextPath}
            />
          </Container>
        </>
      )}
    </Layout>
  );
};

export default PartnerIndex;
