import styled from 'styled-components';

import { Section } from 'components/Container';
import { SearchBar } from 'components/SearchBar';
import { getDimension } from 'styled/helpers/getDimension';
import { AnimatedContainer } from 'components/Container/AnimatedContainer';

import type { FC } from 'react';

const StyledSection = styled(Section)`
  display: flex;
  max-width: 60%;
  flex-direction: column;
  justify-content: center;
  gap: ${getDimension('size2')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export const HeroContainer: FC<{ onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({
  onChange,
}) => (
  <AnimatedContainer>
    <StyledSection>
      <h1>Brokers & apps</h1>
      <p>
        Search for your broker below, then follow our step-by-step guides on how to add your trades
        and dividends in to Sharesight.
      </p>
      <SearchBar onChange={onChange} placeholder="Search broker by name..." />
    </StyledSection>
  </AnimatedContainer>
);
